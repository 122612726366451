import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Tender } from '../../models/tender';
import { TenderTypesEnum } from '../../models/tender-search-body';
import { SharedDocument } from '../../../models/share/share';
import { DocumentSharingTypeEnum } from '../../../models/document-entity';
import { TendersModuleService } from '../../services/tenders-module.service';

@Component({
  selector: 'app-modal-share-watch',
  templateUrl: './modal-share-watch.component.html',
  styleUrls: ['./modal-share-watch.component.scss']
})
export class ModalShareWatchComponent implements OnInit{
  public TenderTypesEnum = TenderTypesEnum;

  @Input() tender!: Tender;
  @Input() inquirers!: string;
  @Input() department!: string;
  @Input() note!: string;

  displayNote = true;
  showLoader = false;
  route = window.location;
  document!: SharedDocument;
  emailShare: boolean = false;


  constructor(
    private activeModal: NgbActiveModal,
    private tendersModuleService: TendersModuleService
  ) {
  }

  ngOnInit() {
    this.document = new SharedDocument({
      document_uid: this.tender.id,
      type: DocumentSharingTypeEnum.TENDER_DOCUMENT,
      title: this.tender.title,
      indexation_date: this.tender.indexationDate,
      closing_date: this.tender.closingDate,
      territories: this.tender.territories,
      inquirers: this.tender.inquirers,
      tender_type: this.tender.tenderType,
      market_type: this.tender.marketType?.join(','),
      note: this.tender.note,
      status: this.tendersModuleService.statusItems.find((item) => item.value.id === this.tender.statusId)?.value.name,
    })
  }

  toggleCheckbox() {
    this.showLoader = true;
    this.displayNote = !this.displayNote;
    setTimeout(() => {
      this.showLoader = false;
    }, 300)
  }

  confirm() {
    const div = document.getElementById('contentToShare');
    if (div) {
      const textContent = this.getTextFromNode(div);
      this.activeModal.close(textContent);
    }
  }

  cancel() {
    this.activeModal.dismiss();
  }

  getTextFromNode(node: Node): string {
    let text = '';
    if (node.nodeType === Node.TEXT_NODE) {
      text += node.textContent;
    } else if (node.nodeType === Node.ELEMENT_NODE) {
      node.childNodes.forEach(childNode => {
        text += this.getTextFromNode(childNode);
        text += '\n'
      });
    }
    return text.trim();
  }

  removeEm(title: string) {
    return title.replace(/<\/?em>/g, '');
  }

}
