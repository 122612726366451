import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TendersModuleService } from '../../services/tenders-module.service';
import { Tender } from '../../models/tender';
import { DbTendersUser } from '../../../models/user';
import { ApiAnnotationService } from '../../services/api/status/api-annotation.service';

@Component({
  selector: 'app-tender-assignment',
  templateUrl: './tender-assignment.component.html',
  styleUrls: ['./tender-assignment.component.scss']
})
export class TenderAssignmentComponent implements OnChanges {

  @Input({required: true}) tender!: Tender;
  @Input() size: 'small' | 'medium' = 'medium';
  @Input() assigneeId?: number;
  assignee?: DbTendersUser;
  users!: DbTendersUser[];

  constructor(protected tendersModuleService: TendersModuleService, private apiAnnotationService: ApiAnnotationService) {
  }

  async ngOnChanges(changes: SimpleChanges) {
    if (changes['tender']) {
      this.users = (await this.tendersModuleService.groupAccountUsers).concat(await this.tendersModuleService.user);
      this.assignee = this.findUserFromId(this.tender.assignedUserId);
    }
    if (changes['assigneeId']) {
      this.assignee = this.findUserFromId(this.assigneeId);
    }
  }

  async assignTo(target: DbTendersUser | null) {
    let res;
    if (this.tender.tenderAnnotationsId) {
      res = await this.apiAnnotationService.updateAnnotation({id: this.tender.tenderAnnotationsId, assigned_user_id: target && target.id})
    } else {
      res = await this.apiAnnotationService.addAnnotation({tender_uid: this.tender.id, assigned_user_id: target?.id})
    }
    if (res) {
      this.tender.tenderAnnotationsId = res.tender_annotation_id;
      this.tender.assignedUserId = target?.id;
      this.assignee = target ?? undefined;
      this.tendersModuleService.updateTenderInRegisteredServices(
        this.tender.id, { assignedUserId: target?.id, tenderAnnotationsId: res.tender_annotation_id }
      );
    }
  }

  updateAssignment() {
    this.apiAnnotationService.getAnnotation(this.tender.id).then(async (annotation) => {
      if (!this.tender.tenderAnnotationsId) this.tender.tenderAnnotationsId = annotation.id;
      if (this.assignee?.id !== annotation.assigned_user_id) {
        this.assignee = this.findUserFromId(annotation.assigned_user_id);
      }
    })
  }

  findUserFromId(id: number | undefined) {
    return this.users.find((user) => user.id === id);
  }
}
