import { firstValueFrom } from 'rxjs';
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { GridQuestion, GridQuestionTypeEnum, CCTP_DISPLAYED_NAME } from '../../models/grid-question';
import { DceDocument } from '../../models/dce-document';
import { UserTrackerService } from '../../../shared/services/tracking/user-tracker.service';
import { EventTypeName } from '../../../models/user-tracker';
import { GridAnswer, TextItem } from '../../models/grid-answer';
import { isEqual } from 'lodash';
import { ChunkDetail } from '../../models/chunk-detail';

@Component({
  selector: 'app-tender-smart-grid',
  templateUrl: './tender-smart-grid.component.html',
  styleUrls: ['./tender-smart-grid.component.scss']
})
export class TenderSmartGridComponent implements OnChanges {
  protected readonly GridQuestionTypeEnum = GridQuestionTypeEnum;

  @Input() tenderId!: string;
  @Input() tenderDceProjectUid!: boolean;
  @Input() dceDocuments: DceDocument[] = [];
  @Input() clickedAnswer: GridAnswer | null = null;
  @Input() clickedRef: ChunkDetail | null = null;
  @Input() privateQuestions: GridQuestion[] = [];
  @Input() publicQuestions: GridQuestion[] = [];
  @Input() fetchPrivateQuestionsFinished = false;
  @Input() fetchPublicQuestionsFinished = false;

  @Output() editQuestionTrigger = new EventEmitter<GridQuestion>();
  @Output() addQuestionTrigger = new EventEmitter<void>();
  @Output() clickedAnswerChange = new EventEmitter<GridAnswer | null>();
  @Output() clickedRefChange = new EventEmitter<ChunkDetail | null>();

  showLoader = true;
  isCopied = false;
  protected isEqual = isEqual;
  clickedRefIndex: number | undefined = undefined;
  clickedQuestion: GridQuestion | null = null;


  constructor(private userTrackerService: UserTrackerService) {
  }


  ngOnChanges(changes: any) {
    if ((this.fetchPublicQuestionsFinished && this.fetchPrivateQuestionsFinished) || !this.tenderDceProjectUid) {
      this.showLoader = false;
    }
  }

  onClickQuestion(question: GridQuestion) {
    if (question.answer && !question.isCctpQuestion()) {
      this.clickedRefIndex = undefined;
      const ref = Object.keys(question.answer.chunkDetails)[0];
      this.isCopied = false;
      this.clickedQuestion = question;
      this.trackEvent({is_question_selected: this.clickedAnswer !== question.answer, question: question},
        EventTypeName.TENDER_GRID_QUESTION_CLICKED);
      this.clickedRefChange.emit(this.clickedRef === question.answer.chunkDetails[ref] ? null : question.answer.chunkDetails[ref]);
    }
  }

  onClickRef(item: TextItem, question: GridQuestion) {
    if (this.clickedRefIndex !== item.index) {
      this.clickedRefIndex = item.index;
    } else {
      this.clickedRefIndex = undefined;
    }
    if (question.answer && item.ref) {
      this.clickedRefChange.emit(
        this.clickedRef === question.answer.chunkDetails[item.ref] && this.clickedRefIndex == undefined
          ? null : question.answer.chunkDetails[item.ref]);
    }

  }

  checkIfQuestionIsSelected(question: GridQuestion) {
      return isEqual(this.clickedQuestion, question) && this.clickedRefIndex == undefined;
  }

  copyToClipboard(event: any, question: GridQuestion) {
    event.stopPropagation();
    navigator.clipboard.writeText(question.displayedName + ' :\n' + question.answer?.text);
    this.isCopied = true;
  }

  trackEvent(data = {}, eventName = '') {
    firstValueFrom(this.userTrackerService.track({
      event_type: eventName,
      event_timestamp: (new Date()).toISOString(),
      ...this.userTrackerService.buildBasicUserInformations(),
      tender_id: this.dceDocuments[0].rawKey.split('/')[3],
      ...data
    }));
  }

  trackByQuestionId(index: number, item: GridQuestion) {
    return item.questionId;
  }

  editQuestion(question: GridQuestion) {
    this.editQuestionTrigger.emit(question);
  }

  checkIfTenderOpenedAfterLimitDate() {
    return (this.publicQuestions.every((question) =>
        !question.answer && question.isGenerationFinished && !question.isGenerationFailed)
      && this.privateQuestions.every((question) =>
        !question.answer && question.isGenerationFinished && !question.isGenerationFailed));
  }

  onClickAddQuestion() {
    this.addQuestionTrigger.emit();
  }

  getCctpQuestion() {
    return this.publicQuestions.filter((question) => question.isCctpQuestion());
  }

  getNonCctpQuestions() {
    return this.publicQuestions.filter((question) => question.displayedName !== CCTP_DISPLAYED_NAME);
  }



  protected readonly undefined = undefined;
}
