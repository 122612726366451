<ng-template #countLoader let-item=item>
  <span *ngIf="countOf(item) !== undefined">({{countOf(item)}})</span>
  <span *ngIf="countOf(item) === undefined" class="mini-spinning-loader">
    <i class="ph ph-circle-notch"></i>
  </span>
</ng-template>

<div>
  <div class="chips d-flex">
    <ng-container *ngFor="let item of selectedItems">
      <ex-chip [backgroundColor]="item.value.backgroundColor ?? computeStatusBackground(item)"
               [color]="item.value.color ?? ''"
               (closeClick)="removeStatus(item)"
               class="text-span-container text-basic no-select">
        <span class="text-span">{{item.label}}</span><ng-container *ngTemplateOutlet="countLoader; context: {item: item}"></ng-container>
      </ex-chip>
    </ng-container>

    <div *ngIf="availableItems.length" class="chips-dropdown d-flex">
      <ex-dropdown noInput noChevron noBorder headerBackgroundColor="transparent"
        [items]="availableItems"
        (change)="addStatus($event)">
        <ng-template *ngIf="selectedItems.length !== 0" exDropdownDisplay>
          <div class="chevron-btn">
            <i class="ph-bold ph-caret-down"></i>
          </div>
        </ng-template>
        <ng-template *ngIf="selectedItems.length === 0" exDropdownDisplay>
          <div class="marine-btn">
            <div class="marine-text">
              {{ 'Statut '}}
            </div>
            <i class="ph-bold ph-plus"></i>
          </div>
        </ng-template>
        <ng-template exDropdownItem
                     let-elm>
          <div class="item d-flex">
            <div class="color-square d-flex align-items-center justify-content-start">
              <svg width="16" height="16" fill="currentColor" [style.color]="elm.value.color">
                <path d="M12 14.5H4C2.89733 14.5 2 13.6027 2 12.5V4.5C2 3.39733 2.89733 2.5 4 2.5H12C13.1027 2.5 14 3.39733 14 4.5V12.5C14 13.6027 13.1027 14.5 12 14.5Z" fill="currentColor"/>
              </svg>
            </div>
            <div class="d-flex align-items-center justify-content-between w-100">
              <div class="d-inline-flex">{{elm.label}} <ng-container *ngTemplateOutlet="countLoader; context: {item: elm}"></ng-container>
              </div>
            </div>
          </div>
        </ng-template>
      </ex-dropdown>

    </div>
  </div>
</div>
