import {
  booleanAttribute,
  Component,
  ContentChild,
  Directive, ElementRef,
  EventEmitter,
  HostListener,
  Input, OnChanges,
  Output, Renderer2, SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { ConnectedPosition, OverlayModule, ScrollDispatcher } from '@angular/cdk/overlay';
import { mod } from '../../../shared/helpers/math';
import { CommonModule } from '@angular/common';

export interface DropdownItem<T = any> extends DisplayItem<T> {
  description?: string;
  icon?: string;
  disabled?: boolean;
  meta?: object;
}

export interface DisplayItem<T> {
  label: string;
  value: T;
}

type ExDropdownContext<T> = {
  $implicit: T | undefined;
  open: boolean;
  toggle: (open?: boolean) => void;
}

@Directive({
  selector: '[exDropdownV2Header]',
  standalone: true
})
export class ExDropdownV2HeaderDirective<T> {
  static ngTemplateContextGuard<TContext>(
    dir: ExDropdownV2HeaderDirective<TContext>, ctx: unknown
  ): ctx is ExDropdownContext<TContext> {
    return true;
  }
}

@Directive({
  selector: '[exDropdownV2Content]',
  standalone: true
})
export class ExDropdownV2ContentDirective<T> {
  static ngTemplateContextGuard<TContext>(
    dir: ExDropdownV2ContentDirective<TContext>, ctx: unknown
  ): ctx is ExDropdownContext<TContext> {
    return true;
  }
}

@Component({
  selector: 'ex-dropdown-v2',
  templateUrl: './ex-dropdown-v2.component.html',
  styleUrls: ['./ex-dropdown-v2.component.scss'],
  imports: [
    CommonModule,
    OverlayModule
  ],
  standalone: true
})
export class ExDropdownV2Component<T> implements OnChanges {
  @Input() type?: 'dropdown' | 'multiselect' = 'dropdown';
  @Input() size?: 'small' | 'medium' | 'x-large' | 'giant' = 'medium';
  @Input() variant: 'raised' | 'ghost' | 'tonal' = 'raised';
  @Input({transform: booleanAttribute}) disabled = false;

  @ContentChild(ExDropdownV2HeaderDirective, {read: TemplateRef}) headerTemplate?: TemplateRef<ExDropdownContext<T>>;
  @ContentChild(ExDropdownV2ContentDirective, {read: TemplateRef}) contentTemplate?: TemplateRef<ExDropdownContext<T>>;

  @Input() value?: T;
  @Output() valueChange = new EventEmitter<T>();

  @Input() icon?: string;
  @Input() label?: string;
  @Input() placeholder?: string;
  @Input() inputText?: string;
  @Input() items?: T[];
  @Input() overlayPositions: ConnectedPosition[] = [
    {originX: 'start', originY: 'bottom', overlayX: 'start', overlayY: 'top'},
    {originX: 'start', originY: 'top', overlayX: 'start', overlayY: 'bottom'},
    {originX: 'end', originY: 'bottom', overlayX: 'end', overlayY: 'top'},
    {originX: 'end', originY: 'top', overlayX: 'end', overlayY: 'bottom'},
  ];

  @Input() values?: string[];
  @Output() valuesChange = new EventEmitter<string[] | undefined>();

  @Output() change = new EventEmitter<DropdownItem | null>();
  @Output() textChange = new EventEmitter<string>();
  @Output() dropdownToggle = new EventEmitter<boolean>();
  @Output() outsideClick = new EventEmitter<boolean>();

  text: string = "";
  selection?: DropdownItem | DropdownItem[];
  open = false;
  filteredItems?: DropdownItem[];

  @ViewChild('input') input?: ElementRef;
  @ViewChild('itemsContainer') itemsContainer?: ElementRef;
  focusIndex?: number;

  constructor(
    private elementRef: ElementRef, private renderer: Renderer2, private scrollDispatcher: ScrollDispatcher
  ) {}

  get headerContext(): ExDropdownContext<T> {
    return {
      $implicit: this.value,
      open: this.open,
      toggle: (bool) => this.toggle(bool),
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['inputText'] && this.inputText) {
      this.text = this.inputText;
    }
  }

  toggle(open?: boolean) {
    const newState = open ?? (!this.open || this.input?.nativeElement === document.activeElement);
    if (this.open !== newState) this.dropdownToggle.emit(newState);
    this.open = newState;
    if (this.open) {
      this.update();
    }
  }

  update(value?: string) {
    const newValue = value?.trim() || this.text.trim();
    this.textChange.emit(newValue);
    if (newValue) {
    } else {
      this.select(null)
    }
    this.focusIndex = 0;
  }

  select(item: DropdownItem | null, propagate = true) {
    if (this.selection == item) return;
    this.selection = item || undefined;
    this.values = this.selection?.value;
    this.valuesChange.emit(this.values);
    this.text = item?.label || '';
    this.focusIndex = undefined;
    if (propagate) this.toggle(false);
    if (propagate) this.update();
    if (propagate) this.change.emit(item);
  }

  clear() {
    this.text = '';
    this.textChange.emit('');
    this.select(null);
  }

  isDisabled(item: DropdownItem) {
    return item.disabled ||
      !!(this.selection && (
          (this.selection instanceof Array && this.selection.includes(item)) ||
          (!(this.selection instanceof Array) && this.selection.value === item.value))
      )
      ;
  }

  @HostListener('document:keydown.ArrowUp', ['$event'])
  @HostListener('document:keydown.ArrowDown', ['$event'])
  onArrow(event: KeyboardEvent) {
    if (!this.filteredItems) {
      return;
    }
    event.preventDefault();
    if (!this.open && this.input?.nativeElement === document.activeElement)
      return this.toggle(true);
    if (this.focusIndex !== undefined) {
      this.focusIndex =
        mod(this.focusIndex + (event.key === 'ArrowUp' ? -1 : 1), this.filteredItems.length);
      this.itemsContainer?.nativeElement.children[this.focusIndex]?.scrollIntoView({block: 'nearest'});
    } else {
      this.focusIndex = (event.key === 'ArrowUp' ? (this.filteredItems.length) - 1 : 0);
    }
  }

  clickOutside(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.outsideClick.emit(true);
      this.toggle(false);
    }
  }

  disableScroll() {
    const scrollables = this.scrollDispatcher.getAncestorScrollContainers(this.elementRef);
    if (!scrollables.length) return;
    const scrollContainer = scrollables[0].getElementRef().nativeElement;
    this.renderer.addClass(scrollContainer, 'overflow-hidden');
  }

  enableScroll() {
    const scrollables = this.scrollDispatcher.getAncestorScrollContainers(this.elementRef);
    if (!scrollables.length) return;
    const scrollContainer = scrollables[0].getElementRef().nativeElement;
    this.renderer.removeClass(scrollContainer, 'overflow-hidden');
  }

  @HostListener('document:keydown.Enter', ['$event'])
  @HostListener('document:keydown.Tab', ['$event'])
  onEnter(event: KeyboardEvent) {
    if (this.open) {
      event.preventDefault();
      if (this.focusIndex !== undefined) {
        this.select(this.filteredItems?.[this.focusIndex] || null);
      }
    }
  }

  @HostListener('document:keydown.Escape', ['$event'])
  onEscape(event: KeyboardEvent) {
    if (event.target instanceof Element && event.target.tagName === 'INPUT') {
      event.preventDefault();
      this.toggle(false);
    }
  }

  @HostListener('mousemove')
  onMouseMove() {
    this.focusIndex = undefined;
  }
}

